import "./photoalbum.scss";
import { showModalDialog, hideModalDialog } from "../dialog";
import * as dompack from "dompack";
import * as preload from 'dompack/extra/preload';
import dragScroller from "../dragscroller";
import SwipeDetect from "../swipedetect.es";

class Photoalbum
{
  constructor( node )
  {
    this.node = node;

    this.thumbs = node.querySelectorAll(".thumblist > li");
    this.photos = JSON.parse(this.node.dataset.photos);

    window.addEventListener("resize", ev => this.loadThumbs(ev) );
    window.addEventListener("load", ev => this.loadThumbs(ev) );

    if( window.event_supportspassive )
      window.addEventListener("scroll", ev => this.loadThumbs(ev), { passive: true } );
    else
      window.addEventListener("scroll", ev => this.loadThumbs(ev) );

    let i = 0;
    for( let node of this.thumbs )
    {
      let idx = i;
      node.addEventListener("click", ev => this.showOverlay(ev, idx) );
      ++i;
    }

    this.resizefn = this.setImageSize.bind(this);
    this.keynavfn = this.onKeyDown.bind(this);

    this.loadThumbs();
  }

  getWindowSize()
  {
    this.viewport = { x : window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
                    , y : window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
                    };
    return this.viewport;
  }

  showOverlay(ev, idx)
  {
    this.activeidx = idx;

    this.overlaycontentnode = dompack.create("div", { className : "photoalbum-overlay"});
    let dialognode = showModalDialog( this.node.dataset.title, this.overlaycontentnode, { theme : "photoalbum" } );
    dialognode.addEventListener("dialog:hide", ev => this.onHideOverlay() );

    this.slidesnode = dompack.create("div", {className : "slides"} );
    this.overlaycontentnode.appendChild(this.slidesnode);

    this.currentimage = this.createImage(this.activeidx);
    this.slidesnode.appendChild(this.currentimage);

    let swp = new SwipeDetect(this.slidesnode);
    swp.init();
    this.slidesnode.addEventListener("wh:swipe", function(ev)
    {
      if( ev.detail.direction == "e" )
        this.previousImage();
      else if( ev.detail.direction == "w" )
        this.nextImage();
    }.bind(this));

    // navigation
    this.previousnode = dompack.create("div", {className : "fa fa-angle-left previousimage"});
    this.slidesnode.appendChild( this.previousnode );
    this.previousnode.addEventListener("click", ev => this.previousImage());
    if( idx == 0 )
      this.previousnode.classList.add("disabled");

    document.body.addEventListener("keydown", this.keynavfn );

    this.nextnode = dompack.create("div", {className : "fa fa-angle-right nextimage"});
    this.slidesnode.appendChild( this.nextnode );
    this.nextnode.addEventListener("click", ev => this.nextImage());
    if( idx >= this.photos.length - 1 )
      this.nextnode.classList.add("disabled");

    let thumbsscroller = dompack.create("div", {className : "thumbnails"});
    thumbsscroller.style.height = "0";//set height zero for animation

    this.overlaycontentnode.appendChild(thumbsscroller);
    let thumblistnode = dompack.create("ul");
    thumbsscroller.appendChild(thumblistnode);
    for( let i = 0; i < this.photos.length; ++i )
    {
      let thumbnode = dompack.create("li", { dataset : { idx : i } });
      if( this.photos[i].bgcolor )
        thumbnode.style.backgroundColor = this.photos[i].bgcolor;

      if( i == this.activeidx )
        thumbnode.classList.add("active");

      if( this.photos[i].title )
        thumbnode.setAttribute("title", this.photos[i].title);

      let aspect = this.photos[i].image.height / this.photos[i].image.width;
      thumbnode.style.width = ~~(85 / aspect) + "px"; //fixed height is 85px
      thumblistnode.appendChild(thumbnode);

      this.preloadImage( thumbnode, this.photos[i].thumb.link );
    }

    this.thumbsnav = new dragScroller( thumbsscroller, { scrollvertical : false
                                                       , contentnode    : thumblistnode
                                                       });

    thumbsscroller.clientWidth;//force css update
    thumbsscroller.style.height = "";

    window.addEventListener("resize", this.resizefn);
    this.setImageSize();

    dialognode.addEventListener("dialog:aftershow", ev => this.thumbsnav.moveActiveIntoView() );

    thumblistnode.addEventListener("click", ev => {
      let node = dompack.closest(ev.target, "li");
      if( node )
        this.gotoImage( 1*node.dataset.idx );
    });
  }

  createImage( idx )
  {
    let aspect = this.photos[idx].image.height / this.photos[idx].image.width;
    let imagenode = dompack.create("div", {className : "image"} );
    imagenode.style.maxWidth = this.photos[idx].image.width + "px";
    imagenode.style.maxHeight = this.photos[idx].image.height + "px";

    imagenode.style.backgroundColor = this.photos[idx].bgcolor;
    if( this.photos[idx].title )
      imagenode.setAttribute("title", this.photos[idx].title);

    let sizernode = dompack.create("div", { className : "image__sizer" });
    sizernode.style.paddingTop = (aspect * 100) + "%";
    imagenode.appendChild( sizernode );

    if( this.photos[idx].title )
      imagenode.appendChild( dompack.create("div", {className : "title", textContent : this.photos[idx].title }) );

    this.preloadImage(imagenode, this.getWindowSize().x > 1024 ? this.photos[idx].image2.link : this.photos[idx].image.link);

    return imagenode;
  }

  onHideOverlay()
  {
    window.removeEventListener("resize", this.resizefn);
    document.body.removeEventListener("keydown", this.onKeyDown );
  }

  previousImage()
  {
    if( this.activeidx > 0 )
      this.gotoImage( this.activeidx - 1 );
  }

  nextImage()
  {
    if( this.activeidx < this.photos.length - 1 )
      this.gotoImage( this.activeidx + 1 );
  }

  gotoImage(idx)
  {
    if( this.activeidx == idx || this.busy )
      return;

    if( idx == 0 )
      this.previousnode.classList.add("disabled");
    else
      this.previousnode.classList.remove("disabled");

    if( idx >= this.photos.length - 1 )
      this.nextnode.classList.add("disabled");
    else
      this.nextnode.classList.remove("disabled");

    this.busy = true;
    this.nextimage = this.createImage( idx );

    this.nextimage.style.transform = "translate3d(" + ( idx > this.activeidx ? this.viewport.x : -this.viewport.x ) + "px,0,0)";
    this.slidesnode.appendChild(this.nextimage);
    this.setImageSize({ type : "newimage"});
    this.nextimage.clientWidth;//force css update
    this.nextimage.style.transform = "translate3d(0,0,0)";
    this.currentimage.style.transform = "translate3d(" + ( idx > this.activeidx ? -this.viewport.x : this.viewport.x ) + "px,0,0)";

    for( let node of this.thumbsnav.node.querySelectorAll("li") )
    {
      if( node.dataset.idx == "" + idx )
        node.classList.add("active");
      else
        node.classList.remove("active");
    }

    this.thumbsnav.moveActiveIntoView();

    setTimeout(function(){
      this.activeidx = idx;
      this.slidesnode.removeChild(this.currentimage);
      this.currentimage = this.nextimage;

      this.busy = false;
    }.bind(this), 500);
  }

  setImageSize( ev )
  {
    if( !this.viewport || (ev && ev.type == "resize") )
      this.getWindowSize();

    let spacing = { x : (this.viewport.x < 900 ? (this.viewport.x < 600 ? 20 : 100) : 250), y : 250 };

    for( let node of this.overlaycontentnode.querySelectorAll(".image") )
    {
      let w = node.style.maxWidth.replace(/[^0-9]/g, "");
      let h = node.style.maxHeight.replace(/[^0-9]/g, "");
      let aspect = h / w;
      if( w > this.viewport.x - spacing.x )
      {
        w = this.viewport.x - spacing.x;
        h = ~~(w * aspect);
      }
      if( h > this.viewport.y - spacing.y )
      {
        h = this.viewport.y - spacing.y;
        w = ~~(h / aspect);
      }
      node.style.width = w + "px";
      node.style.marginLeft = -w/2 + "px";
      node.style.marginTop = -h/2 + "px";
    }
  }

  onKeyDown(ev)
  {
    if( ev.keyCode == 37 ) //left
    {
      ev.preventDefault();
      this.previousImage(ev);
    }
    else if( ev.keyCode == 39 ) //right
    {
      ev.preventDefault();
      this.nextImage(ev);
    }
  }

  loadThumbs(ev)
  {
    if( !this.viewport || (ev && ev.type == "resize") )
      this.getWindowSize();

    for( let node of this.thumbs )
    {
      if( !node.classList.contains(".loaded") )
      {
        let pos = node.getBoundingClientRect();
        if( pos.bottom > 0 && pos.top < this.viewport.y )
        {
          node.classList.add("loaded");
          this.preloadImage( node, node.dataset.thumb );
        }
      }
    }
  }

  async preloadImage( wrappernode, imgsrc )
  {
    let preloadedimage = await preload.promiseImage( imgsrc );
    if( preloadedimage && wrappernode )
    {
      if( wrappernode.title )
        preloadedimage.node.setAttribute( "title", wrappernode.title);
      preloadedimage.node.style.opacity = 0;
      wrappernode.appendChild( preloadedimage.node );
      wrappernode.clientWidth;// force css update
      preloadedimage.node.style.opacity = 1;
    }
  }
}

dompack.register(".photoalbum", node => new Photoalbum( node ) );
