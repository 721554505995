import * as dompack from "dompack";

function checkIfExternalLink(evt)
{
  let anchor = dompack.closest(evt.target, "a");
  if (!anchor)
    return;

  if (anchor.hasAttribute("data-popup-video"))
    return;

  if (anchor.href.substr(0,11) == "javascript:")
    return;

  // FIXME: doesn't handle url vars
  let extention = anchor.href.split(".").pop();
  // let extention = anchor.href.substr(-4) == ".pdf";
  if(anchor.hostname != location.hostname
     || extention == "pdf")
  {
    evt.preventDefault();
    window.open(anchor.href, "_blank");
  }
}

window.openExternalLinksInNewTab = function()
{
  window.addEventListener("click", checkIfExternalLink);
}