import * as dompack from "dompack";
//import * as videotools from "../../shared/js/video";
import * as videotools from "./video";
import "./embedded-videos.css";



dompack.register(".wh-video", (node, idx) =>
{
  let wrapper = node.querySelector(".wh-video__wrapper");
  //videotools.initializeVideoNode(wrapper).then(player => { console.log("TEST"); });
});
