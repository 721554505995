/*
NOTE: This library should be rewritten

This is a toggle in which focus in also important


WVHG <- Student Union/wh.util.togglebutton.js <- pthu/wh.togglebutton.js <- ico/wh.togglebutton.js <- mtimeline/wh.menubutton.js <- pthu/popupmenu.js
*/

if (!window.$wh)
  window.$wh = {};

if (!window.$wh.triggerbutton)
  window.$wh.triggerbutton = {};


window.$wh.PanelTriggerButtons = [];

window.menueventhandled = false;

$wh.triggerbutton.register = function(options)
{
  //console.log(options);
  var inst =
        { buttonnode:        options.buttonnode
        , panelnode:         options.panelnode
        , togglebyfocus:     options.togglebyfocus
        , heightmeasurenode: options.heightmeasurenode
        , onActivate:        options.onActivate
        , onDeactivate:      options.onDeactivate
        };
  //console.log(inst);
  window.$wh.PanelTriggerButtons.push(inst);

  var buttonnode = options.buttonnode;
  var panelnode = options.panelnode;

  if( window.event_supportspassive )
    buttonnode.addEventListener("touchstart", $wh.triggerbutton.onTouchStart.bind(this, inst), { passive: true } ); // for much faster opening
  else
    buttonnode.addEventListener("touchstart", $wh.triggerbutton.onTouchStart.bind(this, inst)); // for much faster opening

  buttonnode.addEventListener("mousedown", $wh.triggerbutton.onTouchStart.bind(this, inst));

  if (inst.togglebyfocus)
  {
    if (!buttonnode.getAttribute("tabindex"))
      buttonnode.setAttribute("tabindex", 0);

    // may take focus, but not don't let the user focus it manually
    // (and prevents focussing links within the mobile menu on the desktop)
    panelnode.setAttribute("tabindex", -1);
    //slidemenu.tabIndex = -1;

    buttonnode.addEventListener("focus", $wh.triggerbutton.onFocus.bind(this, inst));

    // listen for elements which are about to lose focus (not supported in Firefox,
    // although they are working on it: https://bugzilla.mozilla.org/show_bug.cgi?id=687787)
    buttonnode.addEventListener("focusout", $wh.triggerbutton.onPotentialFocusLoss.bind(this, inst), true);
    panelnode.addEventListener("focusout", $wh.triggerbutton.onPotentialFocusLoss.bind(this, inst), true);
  }

  panelnode.addEventListener("click", $wh.triggerbutton.check.bind(this, inst));
  panelnode.addEventListener("touchend", $wh.triggerbutton.check.bind(this, inst));

  if( window.event_supportspassive )
    document.addEventListener("touchstart", $wh.triggerbutton.possibleTouchOutside.bind(this, inst), { passive: true } );
  else
    document.addEventListener("touchstart", $wh.triggerbutton.possibleTouchOutside.bind(this, inst), true);
}

$wh.triggerbutton.check = function(inst, evt)
{
  //if (evt.target.getSelfOrParent(".wh-panel-close"))
  if (evt.target.closest(".wh-panel-close"))
  {
    evt.preventDefault(); // otherwise whe'll click/tap through to element behind it when our panel get's pointer-events: none
    $wh.triggerbutton.applyMobileMenuState(false, inst);
  }
}



$wh.triggerbutton.possibleTouchOutside = function(inst, evt)
{
  var inmobilemenu = inst.panelnode.contains(evt.target); // || $("menu-mobile-activate").contains(event.relatedTarget);
  if (inmobilemenu)
    return;
/*
  var intriggerbutton = inst.buttonnode.contains(evt.relatedTarget);
  if (intriggerbutton)
    return;
*/
  if (inst.togglebyfocus)
    $wh.triggerbutton.applyMobileMenuState(false, inst);
}

// touchstart or mousedown
$wh.triggerbutton.onTouchStart = function(inst, event)
{
  if (inst.debug)
    console.log(event.type); //, event.event.buttons);

/*
  if (event.type == "mousedown" && event.event.button != 0)
  {
    event.preventDefault();
    return;
  }
*/

  if (event.target.closest(".temp-ignorebuttonclick"))
    return;

  event.preventDefault(); // selection?
  event.stopPropagation();

  if (inst.debug)
    console.log("toggling");

  $wh.triggerbutton.toggleMobileMenu(event, inst);

  // when you click the first time whe'll get a 'focus' and 'click' event
  window.menueventhandled = true;
  setTimeout(function() { window.menueventhandled = false; }, 0);
}

$wh.triggerbutton.onFocus = function(inst, event)
{
  console.log("Button onFocus");

  event.preventDefault();
  //event.stopImmediatePropagation();
  event.stopPropagation();

  $wh.triggerbutton.applyMobileMenuState(true, inst);
}

/// close the menu if the focus moved to outside the menu
$wh.triggerbutton.onPotentialFocusLoss = function(inst, evt)
{
  var inmobilemenu = inst.panelnode.contains(evt.relatedTarget);
                     //  || inst.buttonnode.contains(evt.relatedTarget)
                     //  || inst.buttonnode == evt.relatedTarget; // || $("menu-mobile-activate").contains(event.relatedTarget);
  if (inmobilemenu)
    return;

  $wh.triggerbutton.applyMobileMenuState(false, inst);

  //if (window.menuisopen && !$("mobilemenu").contains(event.target))
  //  $wh.applyMobileMenuState.delay(100, false);
}

// (either don't allow mobile mode on desktop or use display:none; (but keep working correctly with anims))
$wh.triggerbutton.closeMobileMenu = function(event)
{
  // apply the new state with a delay to allow link clicks in the menu to get through
  // before the menu get's closed
  $wh.triggerbutton.applyMobileMenuState.delay(100, this, false, inst);
}

$wh.triggerbutton.toggleMobileMenu = function(event, inst)
{
  if (window.menueventhandled)
    return;

  var panel_is_active = inst.panelnode.classList.contains("active");

  if (panel_is_active && inst.buttonnode.hasAttribute("data-href"))
  {
    window.location.href = inst.buttonnode.getAttribute("data-href");
    return;
  }

  //console.log(window.menuisopen);
  $wh.triggerbutton.applyMobileMenuState(!panel_is_active, inst);
}

$wh.triggerbutton.applyMobileMenuState = function(open, inst)
{
  if (inst.debug)
    console.log("$wh.triggerbutton.applyMobileMenuState", open, inst);

  window.disable_body_scroll = open;

  var slidemenu = inst.panelnode;
  slidemenu.style.display = "";
  slidemenu.style.height = "";
  slidemenu.clientWidth; // trigger reflow/transitions

  if (open)
  {
    if (inst.heightmeasurenode)
      slidemenu.style.height = inst.heightmeasurenode.offsetHeight + "px";

    slidemenu.classList.add("active");
  }
  else
  {
    if (inst.heightmeasurenode)
      slidemenu.style.height = "";

    slidemenu.classList.remove("active");
  }

  if (open)
  {
    // For Webkit we need a sligh delay, otherwise the focus will
    // interfere with the transition's positioning somehow
    //setTimeout( function() { inst.panelnode.focus(); }, 50);
    inst.panelnode.focus();
    inst.buttonnode.classList.add("active");

    if (inst.onActivate)
      inst.onActivate();
  }
  else
  {
    inst.panelnode.blur(); // make sure that focus is lost so other opens may detect focus loss
    inst.buttonnode.classList.remove("active");

    if (inst.onDeactivate)
      inst.onDeactivate();
  }
}

// TESTME: this seems to fix it
/*
$wh.triggerbutton.limitTouchMoveToWithinMenu = function(evt, inst)
{
  if (window.sitestate.layout != "tablet")
    return;
//    evt.preventDefault();

  // iOS will only skip to move the body when the current container cannot scroll any further.
  // So we keep it scrollable.
  var slidemenu = inst.panelnode;
  if (slidemenu.scrollTop == 0)
    slidemenu.scrollTop = 1;

  if (slidemenu.scrollTop + slidemenu.offsetHeight == slidemenu.scrollHeight)
    slidemenu.scrollTop -= 1;

/ *
  if (!evt.target == slidemenu)
    evt.preventDefault();
* /
}
*/
