import * as dompack from "dompack";

const videoNodeInitialized = Symbol();
const vimeoFrameLoaded = Symbol();

const default_video_playback =
    { autoplay: false
    , muted:    false // don't play audio by default (to enable autostart on iOS 10+, currently only supported for videofile)
    , controls: true  // use the controls the video networks provide
    , loop:     false // only supported in Vimeo
    , poster:   ""    // poster image, currently only supported for videofile
    };

/** @short generate a video player to play the specified video with the specified settings
    @cell video
    @cell video.network 'youtube', 'vimeo', 'youku', 'videofile' (experimental)
    @cell video.id
    @cell playback
    @cell playback.autoplay
    @cell playback.api make sure we can interact with the video (FIXME: current always done on YouTube)
    @cell playback.muted
    @cell playback.start
    @cell playback.end
    @cell playback.controls
    @cell playback.loop
    @cell playback.background (Vimeo play video in background. This hides controls but this activates: loop, mute, autoplay)
*/
function generateVideoNode(video, playback)
{
  //<iframe width="560" height="315" src="//www.youtube.com/embed/Sk9ST8R2SDk" frameborder="0" allowfullscreen></iframe>

  //playback = Object.merge(default_video_playback, playback);
  var mergedoptions = Object.assign(default_video_playback, playback); // make a clone, so we don't edit the original options object
  playback = Object.assign(mergedoptions, playback);

  var playernode, args = [];

  switch(video.network)
  {
    case "youtube":

        // https://developers.google.com/youtube/player_parameters
        args.push("autoplay=" + (playback.autoplay ? "1" : "0") );

        if (video.starttime)
          args.push("start="+Math.floor(video.starttime)); // seconds, whole integer (YouTube also uses t= in the shorturl??)

        if (video.endtime)
          args.push("end="+Math.floor(video.endtime));

        if (!playback.controls)
          args.push("controls=0");

        if (playback.loop)
        {
          console.warn("FIXME: loop for YouTube is untested.");
          args.push("loop=1");
          args.push("playlist="+video.id); // not sure if required in HTML5 player or only in AS3 player
        }

        args.push("rel=0"); // disable 'related video's'

        args.push("enablejsapi=1");
        args.push("origin=" + location.protocol + "//" + location.hostname);

args.push("showinfo=0");

        // ADDME: playsinline parameter for inline or fullscreen playback on iOS
        /*
        YouTube
        -   start=
          & end=
          & controls=0

          & modestbranding=0
          & rel=0
          & showinfo=0
        */

        var youtube_url = "//www.youtube.com/embed/" + video.id;
        if (args.length > 0)
          youtube_url += "?" + args.join("&");

        playernode = __getPlayerFrame();
        playernode.src = youtube_url;
        return playback.api ? getYouTubePlayer(playernode) : getStaticVideoPlayer(playernode);

    case "vimeo":

        // http://developer.vimeo.com/player/embedding
        args.push("autoplay=" + (playback.autoplay ? "1" : "0") );

        if (video.endtime)
          console.warn("setting an endtime doesn't work for Vimeo video's");

        if (!playback.controls)
          console.warn("disabling video controls not possible for Vimeo video's");

        if (playback.loop)
          args.push("loop=1");

        if (playback.background)
          args.push("background=1");

        var vimeo_url = "//player.vimeo.com/video/" + video.id;
        if (args.length > 0)
          vimeo_url += "?" + args.join("&");

        if (video.starttime)
        {
          // #t=3m28s
          var t = video.starttime;
          var minutes = Math.floor(t / 60);
          var seconds = t % 60;
          vimeo_url += "#t=" + minutes + "m" + seconds + "s";
        }

        playernode = __getPlayerFrame();

        // Set vimeoFrameLoaded to true on load (detect if the iframe contents is loaded before the vimeo player script is)
        playernode[vimeoFrameLoaded] = false;
        playernode.addEventListener("load", event => playernode[vimeoFrameLoaded] = true);

        playernode.src = vimeo_url;
        return playback.api ? getVimeoPlayer(playernode) : getStaticVideoPlayer(playernode);

/*
    case "youku":
        // FIXME: autoplay only available in Flash embed version?

        /*
        http://www.360doc.com/content/10/1120/13/96119_70912265.shtml
        http://pastebin.com/JEq23NM5
        * /
        if (playback.autoplay)
          args.push("isAutoPlay=true");

        if (playback.api)
            console.warn("api not supported by Youku ??")

        if (video.starttime)
          console.warn("starttime not supported by Youku ??")
        //  args.push("firsttime=" + Math.floor(video.starttime));

        if (video.endtime)
          console.warn("endtime not supported by Youku ??")

        if (!playback.controls)
          console.warn("disabling video controls not possible for Youku video's ??");

        if (playback.loop)
          console.warn("loop not supported for Youku")
          //args.push("isLoop=true"); // FIXME: should this work???

        // FIXME: The iframe embed doesn't seem to support passing options??
        //ifrm.src = "//player.youku.com/embed/" + video.id;

        args.push("showAd=0");
        args.push("isShowRelatedVideo=false"); // disable 'related video's'

        var youku_url = "http://player.youku.com/player.php/sid/" + video.id + "/v.swf";
        if (args.length > 0)
          youku_url += "?" + args.join("&");

        playernode = __getPlayerFrame();
        playernode.src = youku_url;
        break;
*/

    case "videofile":
        playernode = document.createElement("video");
        if (playback.loop)
          playernode.setAttribute("loop", "");
        if (playback.controls)
          playernode.setAttribute("controls", "");
        if (playback.muted)
          playernode.setAttribute("muted", "");
        if (playback.poster)
          playernode.setAttribute("poster", playback.poster);
        playernode.style.cssText = "width: 100%; height: 100%;";
        playernode.src = video.id;
        return playback.api ? getHtml5Player(playernode) : getStaticVideoPlayer(playernode);

    default:
        console.error("Unknown video type");
        break;
  }

  return playernode;
}

function __getPlayerFrame()
{
  var ifrm = document.createElement("iframe");
  ifrm.style.width = "100%";
  ifrm.style.height = "100%";
  ifrm.setAttribute("frameborder", 0);
  ifrm.setAttribute("allowfullscreen", "");
  return ifrm;
}

function isVideoNodeInitialized(node)
{
  return !!node[videoNodeInitialized];
}

// Returns a Promise that resolves with a VideoPlayer object
function initializeVideoNode(node, options)
{
  if (node[videoNodeInitialized])
    return;

  node.innerHTML = "";
  node[videoNodeInitialized] = true;

  var video = JSON.parse(node.getAttribute("data-video"));
  var opts = node.hasAttribute("data-video-options") ? JSON.parse(node.getAttribute("data-video-options")) : {};
  opts = Object.assign(opts, options);

  return new Promise((resolve, reject) =>
  {
    generateVideoNode(video, opts).then(player =>
    {
      node.appendChild(player.node);
      resolve(player);
    });
  });
}



class StaticVideoPlayer
{
  constructor(node)
  {
    this.node = node;
    this.network = "static";
  }

  /// Play the video
  play()
  {
  }

  /// Pause the video
  pause()
  {
  }

  /// Is the player currently paused?
  getPaused()
  {
    return Promise.resolve();
  }

  /// Has the player finished playing?
  getFinished()
  {
    return Promise.resolve();
  }

  /// Play the video in a loop
  setLoop(loop)
  {
  }

  /// Seek to the given position in the video, in seconds
  seekTo(seconds)
  {
  }

  /// Get the current playback position, in seconds
  getCurrentTime()
  {
    return Promise.resolve();
  }

  /// Get the total length of the video, in seconds
  getDuration()
  {
    return Promise.resolve();
  }

  /// Set the playback volume, range 0-100
  setVolume(volume)
  {
  }

  /// Get the current playback volume, range 0-100
  getVolume()
  {
    return Promise.resolve();
  }

  /// Get a URL for the video
  getVideoUrl()
  {
    return Promise.resolve();
  }

  /// When the player is ready to play, a bubbling "video:ready" event is
  /// dispatched on the player node
  onReady()
  {
    dompack.dispatchCustomEvent(this.node, "video:ready", { bubbles: true
                                                          , cancelable: false
                                                          , detail: { player: this }
                                                          });
  }

  /// When the player starts playing, a bubbling "video:play" event is
  /// dispatched on the player node
  onPlay()
  {
    dompack.dispatchCustomEvent(this.node, "video:play", { bubbles: true
                                                         , cancelable: false
                                                         , detail: { player: this }
                                                         });
  }

  /// When the playback is paused, a bubbling "video:pause" event is dispatched
  /// on the player node
  onPause()
  {
    dompack.dispatchCustomEvent(this.node, "video:pause", { bubbles: true
                                                          , cancelable: false
                                                          , detail: { player: this }
                                                          });
  }

  /// When the player has finished playing the video, a bubbling "video:play"
  /// event is dispatched on the player node
  onFinish()
  {
    dompack.dispatchCustomEvent(this.node, "video:finish", { bubbles: true
                                                           , cancelable: false
                                                           , detail: { player: this }
                                                           });
  }
}

// https://developers.google.com/youtube/iframe_api_reference
class YouTubeVideoPlayer extends StaticVideoPlayer
{
  constructor(node)
  {
    super(node);
    this.network = "youtube";
    this.player = new YT.Player(this.node,
          { events: { onReady: this.onReady.bind(this)
                    , onStateChange: this.onStateEvent.bind(this)
                    }
          });
  }

  play()
  {
    this.player.playVideo();
  }

  pause()
  {
    this.player.pauseVideo();
  }

  getPaused()
  {
    return Promise.resolve(this.player.getPlayerState() == YT.PlayerState.PAUSED);
  }

  getFinished()
  {
    return Promise.resolve(this.player.getPlayerState() == YT.PlayerState.ENDED);
  }

  setLoop(loop)
  {
    this.player.setLoop(!!loop);
  }

  seekTo(seconds)
  {
    this.player.seekTo(seconds, true);
  }

  getCurrentTime()
  {
    return Promise.resolve(this.player.getCurrentTime());
  }

  getDuration()
  {
    return Promise.resolve(this.player.getDuration());
  }

  setVolume(volume)
  {
    this.player.setVolume(volume);
  }

  getVolume()
  {
    return Promise.resolve(this.player.getVolume());
  }

  getVideoUrl()
  {
    return Promise.resolve(this.player.getVideoUrl());
  }

  onStateEvent(event)
  {
    if (event.data == YT.PlayerState.PLAYING)
      this.onPlay();
    else if (event.data == YT.PlayerState.PAUSED)
      this.onPause();
    else if (event.data == YT.PlayerState.ENDED)
      this.onFinish();
  }
}

// https://developer.vimeo.com/player/js-api
// https://github.com/vimeo/player.js
class VimeoVideoPlayer extends StaticVideoPlayer
{
  constructor(node)
  {
    super(node);
    this.network = "vimeo";
    if (this.node[vimeoFrameLoaded]) // iframe is already loaded
      this.initPlayer();
    else // wait for iframe to load
      this.node.addEventListener("load", this.initPlayer.bind(this));
  }

  initPlayer()
  {
    this.player = new Vimeo.Player(this.node);
    this.player.ready().then(this.onReady.bind(this));
    this.player.on("play", this.onPlay.bind(this));
    this.player.on("pause", this.onPause.bind(this));
    this.player.on("ended", this.onFinish.bind(this));
  }

  play()
  {
    if (!this.player)
      return;
    this.player.play();
  }

  pause()
  {
    if (!this.player)
      return;
    this.player.pause();
  }

  getPaused()
  {
    if (!this.player)
      return Promise.reject();
    return this.player.getPaused();
  }

  getFinished()
  {
    if (!this.player)
      return Promise.reject();
    return this.player.getFinished();
  }

  setLoop(loop)
  {
    if (!this.player)
      return Promise.reject();
    this.player.setLoop(loop);
  }

  seekTo(seconds)
  {
    if (!this.player)
      return Promise.reject();
    this.player.setCurrentTime(seconds);
  }

  getCurrentTime()
  {
    if (!this.player)
      return Promise.reject();
    return this.player.getCurrentTime();
  }

  getDuration()
  {
    if (!this.player)
      return Promise.reject();
    return this.player.getDuration();
  }

  setVolume(volume)
  {
    if (!this.player)
      return Promise.reject();
    this.player.setVolume(volume / 100);
  }

  getVolume()
  {
    if (!this.player)
      return Promise.reject();
    return this.player.getVolume().then(volume => volume * 100);
  }

  getVideoUrl()
  {
    if (!this.player)
      return Promise.reject();
    return this.player.getVideoUrl();
  }
}

// https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement
// https://developer.mozilla.org/en-US/docs/Web/Guide/Events/Media_events
class Html5VideoPlayer extends StaticVideoPlayer
{
  constructor(node)
  {
    super(node);
    this.network = "html5";
    this.node.addEventListener("loadedmetadata", this.onReady.bind(this));
    this.node.addEventListener("play", this.onPlay.bind(this));
    this.node.addEventListener("pause", this.onPause.bind(this));
    this.node.addEventListener("ended", this.onFinish.bind(this));
  }

  play()
  {
    this.node.play();
  }

  pause()
  {
    this.node.pause();
  }

  getPaused()
  {
    return Promise.resolve(this.node.paused);
  }

  getFinished()
  {
    return Promise.resolve(this.node.ended);
  }

  setLoop(loop)
  {
    this.node.loop = !!loop;
  }

  seekTo(seconds)
  {
    this.node.currentTime = seconds;
  }

  getCurrentTime()
  {
    return Promise.resolve(this.node.currentTime);
  }

  getDuration()
  {
    return Promise.resolve(this.node.duration);
  }

  setVolume(volume)
  {
    this.node.volume = volume / 100;
  }

  getVolume()
  {
    return Promise.resolve(this.node.volume * 100);
  }

  getVideoUrl()
  {
    return Promise.resolve(this.node.src);
  }
}

// A video player without API
function getStaticVideoPlayer(node)
{
  return Promise.resolve(new StaticVideoPlayer(node, "static"));
}

// A list of player API scripts with status
let playerScripts = {};

// This function is called by the loaded YouTube API script
window.onYouTubeIframeAPIReady = function()
{
  playerScripts.youtube.ready = true;
  for (let waiter of playerScripts.youtube.waiters)
    waiter.deferred.resolve(new YouTubeVideoPlayer(waiter.node));
};

function getYouTubePlayer(node)
{
  if (!playerScripts.youtube)
  {
    playerScripts.youtube = { ready: false
                            , waiters: []
                            };

    var script = document.createElement("script");
    script.src = "//www.youtube.com/iframe_api";
    script.async = true;
    document.head.appendChild(script);
  }

  if (!playerScripts.youtube.ready)
  {
    let deferred = dompack.createDeferred();
    if (!playerScripts.youtube.waiters)
      playerScripts.youtube.waiters = [];
    playerScripts.youtube.waiters.push({ deferred, node });
    return deferred.promise;
  }
  else
    return Promise.resolve(new YouTubeVideoPlayer(node));
}

function getVimeoPlayer(node)
{
  if (!playerScripts.vimeo)
  {
    playerScripts.vimeo = { ready: false
                          , waiters: []
                          };

    var script = document.createElement("script");
    script.src = "//player.vimeo.com/api/player.js";
    script.async = true;
    script.addEventListener("load", event =>
    {
      playerScripts.vimeo.ready = true;
      for (let waiter of playerScripts.vimeo.waiters)
        waiter.deferred.resolve(new VimeoVideoPlayer(waiter.node));
    });
    document.head.appendChild(script);
  }

  if (!playerScripts.vimeo.ready)
  {
    let deferred = dompack.createDeferred();
    if (!playerScripts.vimeo.waiters)
      playerScripts.vimeo.waiters = [];
    playerScripts.vimeo.waiters.push({ deferred, node });
    return deferred.promise;
  }
  else
    return Promise.resolve(new VimeoVideoPlayer(node));
}

function getHtml5Player(node)
{
  return Promise.resolve(new Html5VideoPlayer(node));
}



export { initializeVideoNode
       , isVideoNodeInitialized
       };
