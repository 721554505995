import "./wh.util.togglebutton";
import "./slidemenu.scss";
import * as dompack from "dompack";


var mobilemenuinstr;
let mobilemenu;



function activateSlideMenu()
{
  var mobilemenutoggler = document.querySelector(".mobilesiteheader__togglemenu");
  var mobilemenunode = document.getElementById("slidemenu");

  mobilemenuinstr =
        { buttonnode:    mobilemenutoggler
        , panelnode:     mobilemenunode
        , togglebyfocus: true
        , onActivate:    onActivateMobileMenu
        , onDeactivate:  onDeactivateMobileMenu
        };
  mobilemenu = $wh.triggerbutton.register(mobilemenuinstr);

  document.getElementById("slidemenu-close").addEventListener("click", doCloseMobileMenu);
}

function onActivateMobileMenu()
{
  // Webkit wants to scroll our container to get the focussed menu element into view.
  // We don't want this, we already are transitioning the menu into view and
  // scrolling will make our menu end up in the middle of the screen.
  document.getElementById("slidemenu-container").scrollLeft = 0;

  document.documentElement.classList.add("site--modality--mobilemenu");
}

function onDeactivateMobileMenu()
{
  document.documentElement.classList.remove("site--modality--mobilemenu");
}

function doCloseMobileMenu()
{
  $wh.triggerbutton.applyMobileMenuState(false, mobilemenuinstr);
}

dompack.onDomReady(evt => activateSlideMenu(evt));
