import "./forms.scss";
import * as dompack from "dompack";
import * as forms from '@mod-publisher/js/forms';
import UploadField from '@mod-publisher/js/forms/fields/upload';
import { DateField } from '@mod-publisher/js/forms/fields/datetime';
import ImgEditField from '@mod-publisher/js/forms/fields/imgedit';
//import RTDField from '@mod-publisher/js/forms/fields/rtd';
import * as googleRecaptcha from "@mod-publisher/js/captcha/google-recaptcha";
import * as dialog from 'dompack/components/dialog';
import * as dialogapi from 'dompack/api/dialog';
import '@mod-publisher/js/forms/themes/neutral';//Load neutral styling (optional, but you'll need to supply your own styling for some of the fields below if you skip this)
import './forms.scss';
import './datetime.scss';

//Enable forms and our builtin validation
forms.setup({validate:true});

//Replaces upload fields with a nicer and edit-supporting version
dompack.register(".wh-form__upload", node => new UploadField(node));

dompack.register(".wh-form__date", node => new DateField(node, { datepicker: false, resetcontrol: false}));

dompack.register(".wh-form__imgedit", node => new ImgEditField(node));

//enable the line below AND the googleRecaptcha import if you want to use this recaptcha. you'll also need to enable it in the site profile
googleRecaptcha.setupGoogleRecaptcha();
dialogapi.setupDialogs(options => dialog.createDialog('basesite-dialog', options));//needed for recaptcha

//Open links in option labels in new window
dompack.register(".wh-form .wh-form__optionlabel a", node => {
  node.setAttribute("target","_blank");
});

//Styled (native) pulldown: add wrapper around select element + arrow for css-styling
dompack.register(".wh-form select", selectnode => {
  let wrappernode = dompack.closest(selectnode, ".wh-form__pulldown-styled");
  if( !wrappernode )
  { //if not already has styling wrapper, add..
    wrappernode = <div class="wh-form__pulldown-styled" />;
    selectnode.parentNode.insertBefore(wrappernode, selectnode);
    wrappernode.appendChild(selectnode);
    wrappernode.appendChild(<span class="arrow fa fa-angle-down" />);
  }

  if (MutationObserver)
  {
    if( selectnode.disabled )
      wrappernode.classList.add("wh-form__pulldown-styled--disabled");
    new MutationObserver(function(){
      dompack.toggleClass(wrappernode, "wh-form__pulldown-styled--disabled", selectnode.disabled);
    }).observe(selectnode, {subtree: false, attributes: true, characterData: false});
  }
});
