import 'dompack/browserfix/reset.css';

import "./css/fonts.css";
import "./css/rtd-shared.css";
import "./forms";

import "./css/site-typography.scss";
import "./css/site.scss";
import "./css/site-submenu.scss";
import "./css/site-footer-at-bottom.css";
import "./css/site-header-desktop.scss";
import "./css/site-header-mobile.scss";
import "./css/site-mobilemenu.scss";

import "../pages";

import "./components/crumbpath.scss";
import "./components/slidemenu";
import "./components/embedded-videos";
import "./components/photoalbum";

import "../../shared/utils/externallinksinnewtab";

import * as browser from 'dompack/extra/browser';
import * as dompack from "dompack";

import "@mod-publisher/js/analytics/ga4";


//used for scroll event listener:
window.event_supportspassive = false;
document.createElement("div").addEventListener("test", _ => {}, { get passive() { window.event_supportspassive = true }});


dompack.onDomReady(initSite);



function initSite()
{
  let htmlnode = document.documentElement;

  if (browser.getPlatform() == "ios")
    htmlnode.classList.add("ios");

  if (document.documentElement.classList.contains("wh-widgetpreview"))
    return;

  document.body.addEventListener("click", doHandleTracking);
}

function doHandleTracking(evt)
{
  let tracknode = dompack.closest(evt.target, "[data-ga-category]");
  if (!tracknode)
    return;

  let trackvalue =
          { hitType:       "event"
          , eventCategory: tracknode.getAttribute("data-ga-category") // required
          , eventAction:   tracknode.getAttribute("data-ga-action")   // required
          , eventLabel:    tracknode.getAttribute("data-ga-label")    // optional
          };

  console.log("Tracking", trackvalue);

  ga("send", trackvalue);
}
