import * as dompack from "dompack";
import "./activities.scss";
import "../../src/components/togglebuttons.scss";


let activityitems = [];

let activitysearchform = null;
let node_resultscount = null;
let grid = null;

let debug = false;

/*
Browser issues
- form.checkboxgroups[] not iterateable with 'of' (IE/Edge)
*/


dompack.onDomReady(initActivitiesOverviewPage);




function initActivitiesOverviewPage()
{
  activitysearchform = document.querySelector(".activitiesoverview");
  if (!activitysearchform)
    return; // not on the activity overview page

  grid = activitysearchform.querySelector(".activitiesoverview__items");

  node_resultscount = activitysearchform.querySelector(".contentgrid__resultscountvalue");

  createActivitiesCache();

  initializeFiltersUI();
}

/*
Browser issues
- form.checkboxgroups[] not iterateable with 'of' (IE/Edge)
*/


/*
function initActivitiesOverviewPage()
{

  initializeFiltersUI();

  refreshActivitiesList();
}
*/

function createActivitiesCache()
{
  var itemnodes = document.body.querySelectorAll(".activitiesoverview .activity");
console.info(itemnodes);
  for (let itemnode of itemnodes)
  {
    var itemdata = JSON.parse(itemnode.getAttribute("data-filterdata"));
    if (!itemdata)
      continue; // not a filterable item? skip it

    itemdata.node = itemnode;
    /*
    itemdata.title = itemnode.querySelector(".activity__title").innerText.toLowerCase();
    itemdata.subtitle = itemnode.querySelector(".activity__subtitle").innerText.toLowerCase();
    itemdata.description = itemnode.querySelector(".activity__description").innerText.toLowerCase();
    //roomrec.url = itemnode.querySelector("a").href;
    */
    activityitems.push(itemdata);
  }
}


function initializeFiltersUI()
{
  /*
  var params = getURLParams();

  if ("waar" in params)
  {
    params.waar = params.waar.split(",");
    for(let node of Array.from(activitysearchform.waar)) // IE/Edge need Array.from
    {
      if (params.waar.indexOf(node.value) > -1)
        node.checked = true;
    }
  }
  */

/*
  console.log(activitysearchform);

  activitysearchform.doelgroep = params.doelgroep;
  activitysearchform.activiteit = params.activiteit;
  activitysearchform.trefwoord = params.trefwoord;

  activitysearchform.checked = params.availablenow || false;

  activitysearchform.addEventListener("change", refreshActivitiesList);
  activitysearchform.trefwoord.addEventListener("input", refreshActivitiesList);
  activitysearchform.addEventListener("submit", onSubmit);

  for(let node of Array.from(activitysearchform.waar))
    node.addEventListener("change", refreshActivitiesList);
*/

  activitysearchform.addEventListener("change", doRefreshOverview.bind(this));


console.log("filterui initialized...");

  //if (params.sortby && ["price","size","availablefrom"].contains(params.sortby))
  //  searchform.sortby.value = params.sortby;
}



function getURLParams()
{
  var params = {};

  if (location.search)
  {
    var parts = location.search.substring(1).split('&');

    for (var i = 0; i < parts.length; i++) {
        var nv = parts[i].split('=');
        if (!nv[0]) continue;
        params[nv[0]] = nv[1] || true;
    }
  }

  return params;
}

function onSubmit(evt)
{
//  evt.preventDefault();
//  refreshActivitiesList();
}


function doRefreshOverview()
{
  console.log("refreshActivitiesList");

  //console.log("form", activitysearchform);
  //console.log("grid", grid);

  //let viewtype = activitysearchform.viewmode.value; // not IE compatible :(

  let viewtype = "";
  for(let node of Array.from(activitysearchform.viewmode))
  {
    if (node.checked)
    {
      viewtype = node.value;
      break;
    }
  }

  grid.classList[viewtype=="list"?"add":"remove"]("contentgrid--list");
  grid.classList[viewtype=="grid"?"add":"remove"]("contentgrid--grid");

  /*
  switch(sortby)
  {
    // from cheap to expensive
    case "price":
      rooms.sort(function(a,b) { return a.totalcosts > b.totalcosts ? 1 : -1; });
      break;

    // large to small
    case "size":
      rooms.sort(function(a,b) { return a.area < b.area ? 1 : -1; });
      break;

    // from now to future (unknown last)
    case "availablefrom":
      //rooms.sort(function(a,b) { return (a.availablefrom == 0 || b.availablefrom == 0 ? 1 : -1) || a.availablefrom > b.availablefrom ? 1 : -1; });
      rooms.sort(function(a,b)
      {
        if (a.availablefrom === 0)
          return 1;

        if (b.availablefrom === 0)
          return -1;

        if (a.availablefrom === b.availablefrom)
          return 0;

        return a.availablefrom > b.availablefrom ? 1 : -1;
      });
      break;

    default:
      alert("unknown sort method.");
      break;
  }
  */

/*
Checkboxes
  var locations = [];
  //var locations_titles = [];
  for(let node of Array.from(activitysearchform.waar)) // IE/Edge need Array.from
  {
    if (node.checked)
      locations.push(parseInt(node.value, 0));
      //locations_titles.push(node.getAttribute("title"));
  }
*/

  //var query = activitysearchform.trefwoord.value.trim();
  var filters = { category:      parseInt(activitysearchform.category.value, 10)
                //, searchwords: query != "" ? query.toLowerCase().split(" ") : []
                };

  /*
  console.log(activitysearchform);
  console.log(filters);
  console.log(activityitems);
  */

  var validresults = [];

  for (let item of activityitems)
  {
    let matches = (filters.category == 0 || item.tags.indexOf(filters.category) > -1);

    /*
    if (matches)
    {
      for (let query of filters.searchwords)
      {
        matches = matches &&
                        (  item.title.indexOf(query)       > -1
                        || item.subtitle.indexOf(query)    > -1
                        || item.description.indexOf(query) > -1
                        );
      }
    }
    */

    //console.log(matches, item);

    if (matches)
    {
      validresults.push(item);
      item.node.classList.remove("contentgrid__item--hide");
    }
    else
      item.node.classList.add("contentgrid__item--hide");
  }

  node_resultscount.innerText = validresults.length;

  /*
  // store the shown results
  sessionStorage.searchresults = JSON.stringify(validresults);

  window.bLazy.revalidate();
  */
}

function sortByKey(a, b)
{
  //console.log(sortkey, a, b, a[sortkey], b[sortkey], a[sortkey] > b[sortkey]);
  return a[sortkey] > b[sortkey] ? 1 : -1;
}
